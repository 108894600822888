import API from '../Common/axios';
import { ExportLog } from './types';

export async function getUsersCount() {
  return await API.get<{ confirmed_count: number; not_confirmed_count: number }>('v1/exports/users_count');
}

export async function getLatestExportLog() {
  return await API.get<{ data: ExportLog }>('v1/exports/latest_export_log');
}

export async function getCurrentExportLog() {
  return await API.get<{ data: ExportLog }>('v1/exports/current_export_log');
}

export async function finishExportLog() {
  return API.post('v1/exports/finish', {});
}

export async function getUsersCie() {
  return await API.get<Buffer>('v1/exports/users', { responseType: 'arraybuffer' });
}

export async function getSalariesPaxml() {
  return await API.get<Buffer>('v1/exports/salaries', { responseType: 'arraybuffer' });
}

export async function lockShifts(date: string) {
  return API.post('v1/exports/lock_shifts', { date });
}

export async function unlockShifts() {
  return API.post('v1/exports/unlock_shifts', {});
}

// @ts-nocheck

import * as Yup from 'yup';
import dayjs, { Dayjs } from 'dayjs';

export const validationSchema = Yup.object().shape({
  date: Yup.date()
    .test('end-of-month', '* Måste vara den sista i månaden', function (value) {
      if (!value) return false;

      return new Date(value.getTime() + 86400000).getDate() === 1;
    })
    .test('after-lock-date', '* Måste vara efter senaste låsdatumet ', function (value) {
      if (!value) return false;
      // @ts-ignore
      if (this.options.context && this.options.context['lock_date'])
        if (dayjs(this.options.context['lock_date']).isAfter(dayjs(value), 'day')) return true;
        else return false;
      else return true;
    })
    .required('* Obligatoriskt fält')
    .typeError('* Ogiltigt datum'),
});

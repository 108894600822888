import React, { useEffect, useState, useCallback, ReactElement } from 'react';
import { Formik, FormikHelpers, FormikErrors, yupToFormErrors } from 'formik';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel, { stepLabelClasses } from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import GroupsIcon from '@mui/icons-material/Groups';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import SalariesIcon from '@mui/icons-material/Savings';
import { styled } from '@mui/material/styles';
import { Box, Button, CircularProgress } from '@mui/material';
import {
  finishExportLog,
  getCurrentExportLog,
  getLatestExportLog,
  getSalariesPaxml,
  getUsersCie,
  getUsersCount,
  lockShifts,
  unlockShifts,
} from './exportApi';
import { downloadFile } from '../Common/utilities';
import { ArrowCircleDown } from '@mui/icons-material';
import dayjs, { Dayjs } from 'dayjs';
import { validationSchema } from './exportSchema';
import FormFreeDateField from '../Common/FormFreeItems/FormFreeDateField';
import { useNotify } from '../Common/snackbarHooks';
import { ExportLog } from './types';
import { twMerge } from 'tailwind-merge';

const Export: React.FC = () => {
  const { notifyError, notifySuccess } = useNotify();
  const [activeStep, setActiveStep] = useState(1);
  const [confirmedUsersCount, setConfirmedUsersCount] = useState(0);
  const [notConfirmedUsersCount, setNotConfirmedUsersCount] = useState(0);
  const [currentExportLog, setCurrentExportLog] = useState<ExportLog>();
  const [latestExportLog, setLatestExportLog] = useState<ExportLog>();
  const [loadingUsersCie, setLoadingUsersCie] = useState(false);
  const [loadingSalariesPaxml, setLoadingSalariesPaxml] = useState(false);
  const [unlockingShifts, setUnlockingShifts] = useState(false);
  const [finishingExportLog, setFinishingExportLog] = useState(false);

  useEffect(() => {
    getCurrentExportLog().then(({ data }) => {
      if (data) {
        setCurrentExportLog(data.data);
        setActiveStep(2);
      }
    });

    getLatestExportLog().then(({ data }) => {
      setLatestExportLog(data.data);
    });

    getUsersCount().then(({ data }) => {
      setConfirmedUsersCount(data.confirmed_count);
      setNotConfirmedUsersCount(data.not_confirmed_count);
    });
  }, []);

  const downloadUsersCie = useCallback(() => {
    if (loadingUsersCie) return;

    setLoadingUsersCie(true);
    getUsersCie()
      .then(({ data }) => {
        const blob = new Blob([data], { type: 'application/cie' });
        downloadFile(blob, `anstallda_${dayjs().format('YYYY-MM-DD_HHmmss')}.cie`);
      })
      .finally(() => {
        setLoadingUsersCie(false);
      });
  }, []);

  const downloadSalariesPaxml = useCallback(() => {
    if (loadingSalariesPaxml) return;

    setLoadingSalariesPaxml(true);
    getSalariesPaxml()
      .then(({ data }) => {
        const blob = new Blob([data], { type: 'application/paxml' });
        downloadFile(blob, `lon_${dayjs().format('YYYY-MM-DD_HHmmss')}.pax`);
      })
      .finally(() => {
        setLoadingSalariesPaxml(false);
      });
  }, []);

  const regretLockShifts = useCallback(() => {
    if (unlockingShifts) return;

    setUnlockingShifts(true);
    unlockShifts()
      .then(() => {
        notifySuccess(`Vi har nu ångrat låsning av timmarna.`);
        getCurrentExportLog().then(({ data }) => {
          if (data) {
            setCurrentExportLog(data.data);
          } else {
            setCurrentExportLog(undefined);
            setActiveStep(1);
          }
        });
      })
      .catch((error) => {
        notifyError(`Något gick fel. Kontakta Skry för att få hjälp med problemet.`);
      })
      .finally(() => {
        setUnlockingShifts(false);
      });
  }, []);

  const handleFormSubmit = (values: { date: Dayjs | null }, actions: FormikHelpers<any>): void => {
    if (!values.date) return;

    lockShifts(values.date.format('YYYY-MM-DD'))
      .then(() => {
        notifySuccess(`Vi har nu låst timmarna till och med ${values?.date?.format('YYYY-MM-DD')}`);
        getCurrentExportLog().then(({ data }) => {
          if (data) {
            setCurrentExportLog(data.data);
            setActiveStep(2);
          } else {
            setCurrentExportLog(undefined);
          }
        });
      })
      .catch((error) => {
        notifyError(`Något gick fel vid låsning av timmarna. Kontakta Skry för att få hjälp med problemet.`);
      })
      .finally(() => {
        actions.setSubmitting(false);
      });
  };

  const completeExport = useCallback(() => {
    if (finishingExportLog) return;

    setFinishingExportLog(true);
    finishExportLog()
      .then(() => {
        notifySuccess('Export är slutford.');
        setActiveStep(3);
      })
      .catch((error) => {
        notifyError('Något gick fel med att slutföra exporten. Kontakta Skry för att få hjälp med problemet.');
      })
      .finally(() => {
        setFinishingExportLog(false);
      });
  }, []);

  const CustomStepConnector = styled(StepConnector)(() => ({
    [`&`]: {
      marginLeft: '10px',
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderLeftWidth: 5,
      borderColor: '#439676',
    },
  }));

  const CustomStepLabel = styled(StepLabel)(() => ({
    [`& .${stepLabelClasses.label}`]: {
      [`&.${stepLabelClasses.completed}`]: {
        color: '#439676',
      },
      [`&.${stepLabelClasses.active}`]: {
        color: '#439676',
      },
      color: '#43967650',
    },
  }));

  const CustomStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean };
  }>(({ theme }) => ({
    zIndex: 1,
    color: '#43967650',
    variants: [
      {
        props: ({ ownerState }: { ownerState: { completed?: boolean; active?: boolean } }) => ownerState.active,
        style: {
          color: '#439676',
        },
      },
      {
        props: ({ ownerState }: { ownerState: { completed?: boolean; active?: boolean } }) => ownerState.completed,
        style: {
          color: '#439676',
        },
      },
    ],
  }));

  const CustomStepIcon = (props: StepIconProps) => {
    const { active, completed, className } = props;

    const icons: { [index: string]: React.ReactElement<unknown> } = {
      1: <GroupsIcon />,
      2: <CalendarMonthIcon />,
      3: <AssignmentTurnedInIcon />,
      4: <VerifiedOutlinedIcon />,
    };

    return (
      <CustomStepIconRoot ownerState={{ completed, active }} className={className}>
        {icons[String(props.icon)]}
      </CustomStepIconRoot>
    );
  };

  return (
    <div className="min-h-full bg-white">
      <div className="flex gap-x-2 pt-10 px-10 text-neutral-600 items-center">
        <SalariesIcon />
        <h1 className="flex items-center text-2xl ">
          <span className="font-bold underline">Lönehantering</span>
        </h1>
      </div>

      <div className="flex pt-10 px-10 justify-between">
        <h2 className="flex items-center text-xl text-neutral-600">
          <span className="font-bold">Process för export av lönedata</span>
        </h2>
      </div>

      <div className="flex pt-5 px-10 justify-between">
        <h2 className="flex items-center text-neutral-600">
          <span>
            Exportera användare -{'>'} Lås pass -{'>'} Exportera lönedata -{'>'} Bekräfta lyckad import
          </span>
        </h2>
      </div>

      <div className="pt-5 px-10">
        <Stepper activeStep={activeStep} orientation="vertical" connector={<CustomStepConnector />}>
          <Step key={'Exportera alla godkända anställda'}>
            <CustomStepLabel StepIconComponent={CustomStepIcon} slotProps={{ label: { className: '!text-base pl-2' } }}>
              Exportera alla godkända anställda
            </CustomStepLabel>
            <StepContent
              TransitionComponent={Box}
              className="!border-solid !border-l-[5px] !border-l-[#439676] !ml-[10px]"
            >
              <div className="grid grid-cols-[0.8fr_0.2fr] max-w-[53rem] items-center">
                <div>
                  <div className="pt-4 px-4 text-base text-[#439676]">
                    Exportera <b>{confirmedUsersCount}</b> användare
                  </div>
                  <div className="px-4 text-base text-[#439676]">
                    ({notConfirmedUsersCount} ej godkända användare kommer att ej exporteras)
                  </div>
                </div>
                <div className="flex flex-col gap-y-1 pt-4">
                  <Button
                    className="!py-4 !px-6 w-[169px]"
                    variant="contained"
                    color="primary"
                    disabled={loadingUsersCie}
                    endIcon={loadingUsersCie ? <CircularProgress size="15px" /> : <ArrowCircleDown />}
                    onClick={downloadUsersCie}
                    sx={{
                      '&.Mui-disabled': {
                        background: 'white',
                        color: '#43967680',
                        border: 1,
                        borderColor: '#43967680',
                      },
                      border: 1,
                      borderColor: '#439676',
                    }}
                  >
                    <span className="text-sm font-medium">{loadingUsersCie ? 'Exporterar' : 'Exportera'}</span>
                  </Button>
                  <span className="text-[10px] text-[#439676] italic text-right">Ladda ner anställda</span>
                </div>
              </div>
            </StepContent>
          </Step>
          <Step key={'Valj datum till och med den dag du vill Låsa och Exportera timmar'}>
            <CustomStepLabel StepIconComponent={CustomStepIcon} slotProps={{ label: { className: '!text-base pl-2' } }}>
              Valj datum <b>till och med</b> den dag du vill <u>Låsa & Exportera</u> timmar
            </CustomStepLabel>
            <StepContent
              TransitionComponent={Box}
              className="!border-solid !border-l-[5px] !border-l-[#439676] !ml-[10px]"
            >
              <Formik
                enableReinitialize={true}
                initialValues={{
                  date: currentExportLog
                    ? dayjs(currentExportLog.end_date)
                    : (dayjs(dayjs().subtract(1, 'month')).endOf('month') as Dayjs),
                }}
                validate={(values): Promise<any> => {
                  return validationSchema
                    .validate(values, {
                      abortEarly: false,
                      context: {
                        lock_date: undefined,
                      },
                    })
                    .then(() => Promise.resolve())
                    .catch((err) => Promise.resolve(yupToFormErrors(err))); // In Formik 2.x, rejection will be interpreted as an actual exception and it won't update the form error state, therefore we return a resolved promise of errors instead
                }}
                onSubmit={(values, actions): void => {
                  handleFormSubmit(values, actions);
                }}
              >
                {({ values, handleSubmit, setFieldValue, isSubmitting, isValid }): ReactElement => (
                  <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-[0.57fr_0.43fr] max-w-[53rem] pt-8">
                      <div className="px-4 !w-64 flex flex-col gap-y-1">
                        <FormFreeDateField
                          size={null}
                          label="Lås och Exportera t.o.m"
                          value={values.date}
                          onlyValid={false}
                          onChange={(day) => setFieldValue('date', day)}
                          disabled={currentExportLog ? true : false}
                          sx={{
                            '& .MuiIconButton-root': {
                              color: '#439676 !important',
                            },
                            '& .Mui-disabled .MuiOutlinedInput-input': {
                              WebkitTextFillColor: '#43967680 !important',
                            },
                            '& .MuiOutlinedInput-input': {
                              WebkitTextFillColor: '#439676 !important',
                            },
                            '& .Mui-disabled .MuiOutlinedInput-notchedOutline': {
                              borderColor: '#43967680 !important',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                              borderColor: '#439676 !important',
                            },
                            '& .Mui-disabled.MuiInputLabel-root': {
                              color: '#43967680 !important',
                            },
                            '& .MuiInputLabel-root': {
                              color: '#439676 !important',
                            },
                          }}
                        />
                        <span className="text-[10px] text-[#439676] italic text-right">
                          {latestExportLog ? `Låstes senast ${latestExportLog.locked_at} för pass t.o.m ` : ''}
                          {latestExportLog && <b>{latestExportLog.end_date}</b>}
                        </span>
                      </div>
                      <div className="flex place-content-between">
                        <div className="flex flex-col gap-y-1">
                          <Button
                            type="submit"
                            className="!py-4 !px-4 w-[169px]"
                            variant="contained"
                            color="primary"
                            disabled={!isValid || isSubmitting || currentExportLog || !values.date ? true : false}
                            endIcon={isSubmitting ? <CircularProgress size="15px" /> : <LockOpenIcon />}
                            sx={{
                              '&.Mui-disabled': {
                                background: 'white',
                                color: '#43967680',
                                border: 1,
                                borderColor: '#43967680',
                              },
                              border: 1,
                              borderColor: '#439676',
                            }}
                          >
                            <span className="text-sm font-medium">{isSubmitting ? 'Låser pass' : 'Lås pass'}</span>
                          </Button>
                          <span className="text-[10px] text-[#439676] italic text-right">
                            {currentExportLog
                              ? `Pass låsta t.o.m. ${currentExportLog.end_date}`
                              : values.date
                              ? 'Klicka för att låsa pass'
                              : 'Välj datum för att låsa pass'}
                          </span>
                        </div>

                        <div className="flex flex-col gap-y-1">
                          <Button
                            className="!py-4 !px-6 w-[169px]"
                            variant="contained"
                            color="primary"
                            disabled={loadingSalariesPaxml ? true : currentExportLog ? false : true}
                            endIcon={loadingSalariesPaxml ? <CircularProgress size="15px" /> : <ArrowCircleDown />}
                            sx={{
                              '&.Mui-disabled': {
                                background: 'white',
                                color: '#43967680',
                                border: 1,
                                borderColor: '#43967680',
                              },
                              border: 1,
                              borderColor: '#439676',
                            }}
                            onClick={downloadSalariesPaxml}
                          >
                            <span className="text-sm font-medium">
                              {loadingSalariesPaxml ? 'Exporterar' : 'Exportera'}
                            </span>
                          </Button>
                          <span className="text-[10px] text-[#439676] italic text-right">
                            {currentExportLog ? 'Ladda ned lönedata' : ''}
                          </span>
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </Formik>
            </StepContent>
          </Step>
          <Step key={'Bekräfta lyckad import av lönedata'}>
            <CustomStepLabel StepIconComponent={CustomStepIcon} slotProps={{ label: { className: '!text-base pl-2' } }}>
              Bekräfta lyckad import av lönedata
            </CustomStepLabel>
            <StepContent
              TransitionComponent={Box}
              className="!border-solid !border-l-[5px] !border-l-[#439676] !ml-[10px]"
            >
              <div className="grid grid-cols-[0.57fr_0.43fr] max-w-[53rem] items-center">
                <div className={activeStep < 2 ? 'text-[#43967650]' : 'text-[#439676]'}>
                  <div className="pt-4 px-4 text-base">Om allt ser bra ut, fortsätt genom att</div>
                  <div className="px-4 text-base">bekräfta lyckad data-import i Edison</div>
                </div>
                <div className="flex place-content-between pt-4">
                  <div className="flex flex-col gap-y-1">
                    <Button
                      className="!py-4 !px-2 w-[169px]"
                      variant="contained"
                      color="error"
                      disabled={
                        activeStep === 3 || unlockingShifts || finishingExportLog
                          ? true
                          : currentExportLog
                          ? false
                          : true
                      }
                      endIcon={unlockingShifts ? <CircularProgress size="15px" /> : <CancelOutlinedIcon />}
                      sx={{
                        '&.Mui-disabled': {
                          background: 'white',
                          color: '#43967680',
                          border: 1,
                          borderColor: '#43967680',
                        },
                        border: 1,
                        borderColor: '#D21224',
                      }}
                      onClick={regretLockShifts}
                    >
                      <span className="text-sm font-medium">{unlockingShifts ? 'Ångrar' : 'Ångra låsning'}</span>
                    </Button>
                    <span className="text-[10px] text-[#D21224] italic text-right">
                      {currentExportLog && activeStep === 2 ? 'Återställ data för pass-låsning' : ''}
                    </span>
                  </div>
                  <div className="flex flex-col gap-y-1">
                    <Button
                      className="!py-4 !px-2 w-[169px]"
                      variant="contained"
                      color="primary"
                      disabled={
                        activeStep === 3 || finishingExportLog || unlockingShifts
                          ? true
                          : currentExportLog
                          ? false
                          : true
                      }
                      onClick={completeExport}
                      endIcon={finishingExportLog ? <CircularProgress size="15px" /> : null}
                      sx={{
                        '&.Mui-disabled': {
                          background: 'white',
                          color: '#43967680',
                          border: 1,
                          borderColor: '#43967680',
                        },
                        border: 1,
                        borderColor: '#439676',
                      }}
                    >
                      <span className="text-sm font-medium">
                        {finishingExportLog ? 'Bekräftar' : 'Bekräfta import'}
                      </span>
                    </Button>
                    <span className="text-[10px] text-[#439676] italic text-right">
                      {currentExportLog && activeStep === 2 ? 'Slutför processen' : ''}
                    </span>
                  </div>
                </div>
              </div>
            </StepContent>
          </Step>
          <Step key={'Export slutförd'}>
            <CustomStepLabel StepIconComponent={CustomStepIcon} slotProps={{ label: { className: '!text-base pl-2' } }}>
              Export slutförd
            </CustomStepLabel>
          </Step>
        </Stepper>
      </div>
    </div>
  );
};

export default Export;
